import React from "react";
import nameof from "nameof";
import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import { withLabel } from "../Label";
import ValidationWrapper from "../ValidationWrapper";

export interface DropdownOption {
	key?: string;
	displayValue?: string;
}

export interface DropdownProps {
	placeholder?: string;
	exactPlaceholder?: string;
	autoFocus?: boolean;
	tabIndex?: number;
	selectedValue: any | undefined;
	options: any[] | undefined;
	onChange: (value: any | undefined) => void;
	hideClear?: boolean | undefined;
	style?: object | undefined;
	filter?: boolean;
	filterBy?: string;
	validationMessage?: string | undefined;
	toolTip?: string;
	disabled?: boolean | undefined;
	valueTemplate?: (option: any, props: object) => any | any;
	itemTemplate?: (option: any) => any | any;
}

const optionLabel = nameof<DropdownOption>("displayValue");
const optionKey = nameof<DropdownOption>("key");

// BUG: See T-4249

const Dropdown: React.FC<DropdownProps> = function (props) {
	const selectedValue =
		(props &&
			props.options &&
			props.options.find((o) => o.key === (props && props.selectedValue && props.selectedValue.key))) ||
		undefined;
		let placeholder = `-- ${props.placeholder || "All"} --`;
		if (props.exactPlaceholder) {
			placeholder = props.exactPlaceholder;
		}
	return (
		<ValidationWrapper showErrorState={props.validationMessage != null} validationMessage={props.validationMessage} showValidationMessage={props.disabled}>
			<PrimeDropdown
				showClear={!props.hideClear}
				value={selectedValue}
				valueTemplate={props.valueTemplate}
				itemTemplate={props.itemTemplate}
				options={props.options}
				tooltip={props.validationMessage ?? props.toolTip}
				tooltipOptions={{ event: "hover" }}
				optionLabel={optionLabel}
				dataKey={optionKey}
				filter={props.filter}
				filterBy={props.filterBy}
				onChange={(e) => (e.value === null ? props.onChange(undefined) : props.onChange(e.value))}
				placeholder={placeholder}
				style={props.style}
				appendTo={document.body}
				disabled={props.disabled}
				autoFocus={props.autoFocus}
				tabIndex={props.tabIndex}
			/>
		</ValidationWrapper>
	);
};
export default withLabel(Dropdown);
