import React from "react";
import pageStyle from "./PriorityFilter.module.css";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputWithOperatorFilterSelection } from "./types";

export interface InputWithOperatorFilterProps {
	filter: InputWithOperatorFilterSelection;
	operatorOptions: DropdownOption[];
	onDropdownChange: (e?: string) => void;
	onValueChange: (e?: any) => void;
}

export default function InputWithOperatorFilter(props: InputWithOperatorFilterProps) {
	const { filter } = props;

	let tooltip = "Excl. means that the filter will not be applied." 
	for (const x of props.operatorOptions) {
		switch(x.displayValue)
		{
			case ">":
				tooltip += " > finds records greater than the provided value.";
				break;
			case "<":
				tooltip += "< finds records less than the provided value.";
				break;
			default:
				break;
		}
	}

	return (
		<div className={pageStyle.filter}>
			<div className={pageStyle.operatorFilter}>
				<Dropdown
					selectedValue={props.operatorOptions.find(x => x.key === filter.operator) ?? props.operatorOptions.find(x => x.key == "None")} 
					hideClear={true}
					options={props.operatorOptions}
					onChange={(d) => props.onDropdownChange(d.key)}
					label={filter.label}
					disabled={false}
					style={{ width: 90 }}
				/>
				<InputNumber
					value={filter.value}
					onChange={(e) => e.value == null ? props.onValueChange(undefined) : props.onValueChange(e.value)}
					suffix={filter.inputSuffix}
					disabled={!filter.operator || filter.operator === "None"}
					minFractionDigits={filter.showDecimal ? 1 : 0}
					maxFractionDigits={filter.showDecimal ? 1 : 0}
					step={0.1}
					min={filter.minimumValue || 0}
					size={1}
					style={{ width: 50 }}
				></InputNumber>
			</div>
		</div>
	);
}
