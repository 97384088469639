import React, { useState } from "react";
import useStyle from "./useStyle";
import format from "date-fns/format";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import useFirstLoadEffect from "utils/useMountEffect";
import Dropdown from "components/controls/Dropdown";
import { DropdownOption, StaleUnitReportRequest } from "api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import MultiSelect from "components/controls/MultiSelect";
import constants from "utils/constants";
import { formatPercentage } from "utils/miscUtils";
import InputWithOperatorFilter from "components/controls/InputWithOperatorFilter";
import { InputWithOperatorFilterSelection } from "components/controls/InputWithOperatorFilter/types";

const StaleUnitPage: React.FC = function () {
	const classes = useStyle();
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.staleUnitPageState.filterOptions,
		staleUnitPageReports: appState.staleUnitPageState.staleUnitReports,
		isLoading: appState.staleUnitPageState.isLoading,
		...appState
	};
	const actions = {
		...appActions.staleUnitPage,
		...appActions
	};

	interface LocalState {
		currentFilters: StaleUnitReportRequest;
		appliedFilters: StaleUnitReportRequest;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: {},
		appliedFilters: {}
	});

	useFirstLoadEffect(() => {
		actions.getForm(localState.currentFilters);
	});

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.divisionId = division?.key;
		curSelections.assetCollections = undefined;
		curSelections.propertyIds = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectAssetCollection(assetCollections: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.assetCollections = assetCollections?.map(option => option.key!);
		curSelections.propertyIds = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectProperties(properties: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.propertyIds = properties?.map(option => option.key!);
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectUgAffordabilityType(ugAffordabilityType: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.ugAffordabilityType = ugAffordabilityType?.map(option => option.key!);
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function onSelectUnitStatus(unitStatus: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.unitStatus = unitStatus?.map(option => option.key!);
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function onSelectVacantDays(vacantDays: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.vacantDays = parseInt(vacantDays?.key!);
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function assetCollectionsDropdownDisabled() {
		return pageState.filterOptions!.assetCollections === undefined ||
			pageState.filterOptions!.assetCollections === null ||
			pageState.filterOptions!.assetCollections?.length === 0;
	}

	function propertiesDropdownDisabled() {
		return pageState.filterOptions!.propertyIds === undefined ||
			pageState.filterOptions!.propertyIds === null ||
		 	pageState.filterOptions!.propertyIds?.length === 0;
	}

	function additionalDropdownDisabled() {
		return localState.currentFilters.propertyIds === null ||
		localState.currentFilters.propertyIds === undefined ||
		localState.currentFilters.propertyIds.length === 0;
	}

	function applySearchDisabled() {
		var noColOrPropSelected =
			(localState.currentFilters.assetCollections === null ||
			localState.currentFilters.assetCollections === undefined ||
			localState.currentFilters.assetCollections.length === 0) &&
			(localState.currentFilters.propertyIds === null ||
			localState.currentFilters.propertyIds === undefined ||
			localState.currentFilters.propertyIds.length === 0) || (
				localState.currentFilters.vacantDaysOperator != undefined && localState.currentFilters.vacantDaysOperator != "None" && localState.currentFilters.vacantDays == undefined
			);

		return noColOrPropSelected;
	}

	function applySearch() {
		var curSelections = { ...localState.currentFilters };
		setLocalState({
			...localState,
			appliedFilters: curSelections
		});

		actions.searchProperty(curSelections);
	}

	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;

		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		}
		if (applySearchDisabled() === true) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	function applyExport() {
		window.print();
	}

	const displayReport = () => {
		if (pageState.staleUnitPageReports !== null && pageState.staleUnitPageReports !== undefined) {
			
			if(pageState.staleUnitPageReports.length === 0){
				return <div className={classes.empty}>
					<h3>No Units</h3>
				</div>
			}

			return (
				
				<div className={classes.reportHeading} style={{ pageBreakAfter: "always" }}>
					<div id="unitTypeHeadingMargin2">
						<DataTable value={pageState.staleUnitPageReports}>
							<Column field="division" header="Division" sortable={true}></Column>
							<Column field="propertyName" header="Property Name" sortable={true}></Column>
							<Column 
								field="propertyATR" 
								header="Property ATR"
								body={(e: any) => {
									if (e.propertyATR !== undefined && e.propertyATR !== "") {
										return formatPercentage(e.propertyATR);
									} else {
										return "N/A";
									}
								}}
								>
							</Column>
							<Column field="unitGroup" header="Unit Group" sortable={true}></Column>
							<Column 
								field="unitGroupATR" 
								header="Unit Group ATR"
								body={(e: any) => {
									if(e.unitGroupATR !== undefined && e.unitGroupATR !== "") {
										return formatPercentage(e.unitGroupATR);
									} else {
										return "N/A";
									}
								}}
								>
							</Column>
							<Column field="unitType" header="Unit Type"></Column>
							<Column field="unitCode" header="Unit Code"></Column>
							<Column field="beds" header="Beds"></Column>
							<Column field="unitStatus" header="Unit Status"></Column>
							<Column
							sortable={true}
								field="vacatedDate"
								headerStyle={{ textAlign: "center" }}
								bodyStyle={{ textAlign: "center" }}
								body={(e: any) => {
									if (e.vacatedDate && e.vacatedDate !== "") {
										return format(new Date(e.vacatedDate), "M/d/yyyy");
									} else {
										return "N/A";
									}
								}}
								header="Vacated Date"
							></Column>
							<Column
								field="readyDate"
								sortable={true}
								headerStyle={{ textAlign: "center" }}
								bodyStyle={{ textAlign: "center" }}
								body={(e: any) => {
									if (e.readyDate && e.readyDate !== "") {
										return format(new Date(e.readyDate), "M/d/yyyy");
									} else {
										return "N/A";
									}
								}}
								header="Ready Date"
							></Column>
							<Column field="vacantDays" header="Vacant Days" sortable={true}></Column>
						</DataTable>
					</div>
				</div>
			);
		} else {
			if(localState.currentFilters.propertyIds === null || localState.currentFilters.propertyIds === undefined){
				return <div className={classes.empty}>Please Select A Property Using The Filters Above</div>;
			}
		}
	};

	return (
		<Page menu={<Menu title={constants.menuTitle.staleUnit} />}>
			<div id="staleUnitReportFilters" className={classes.filterHeaders}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={pageState.filterOptions!.divisionId?.find(x => x.key === localState.currentFilters.divisionId)}
						options={pageState.filterOptions!.divisionId}
						onChange={(d) => onSelectDivision(d)}
						disabled={pageState.filterOptions!.divisionId === undefined}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Asset Collections:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"Select asset collections"}
						selectedValues={localState.currentFilters.assetCollections?.map((filterOption) => pageState.filterOptions!.assetCollections?.find(x => x.key === filterOption)! )}
						options={pageState.filterOptions!.assetCollections}
						onChange={(d) => onSelectAssetCollection(d)}
						disabled={assetCollectionsDropdownDisabled()}
						hideClear={false}
						filter
						style={{width: 350}}
						></MultiSelect>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"All"}
						selectedValues={localState.currentFilters.propertyIds?.map((filterOption) => pageState.filterOptions!.propertyIds?.find(x => x.key === filterOption)! )}
						options={pageState.filterOptions!.propertyIds}
						onChange={(d) => onSelectProperties(d)}
						disabled={propertiesDropdownDisabled()}
						hideClear={false}
						filter
						style={{ width: 350 }}
					></MultiSelect>
				</div>
				<div className={classes.filterItem}>
					{"UG Affordability Type:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"All"}
						selectedValues={localState.currentFilters.ugAffordabilityType?.map((filterOption) => pageState.filterOptions!.ugAffordabilityType?.find(x => x.key === filterOption)! )}
						options={pageState.filterOptions!.ugAffordabilityType}
						onChange={(d) => onSelectUgAffordabilityType(d)}
						disabled={additionalDropdownDisabled()}
						hideClear={false}
						filter
						style={{ width: 200 }}
					></MultiSelect>					
				</div>
				<div className={classes.filterItem}>
					{"Unit Status:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"All"}
						selectedValues={localState.currentFilters.unitStatus?.map((filterOption) => pageState.filterOptions!.unitStatus?.find(x => x.key === filterOption)! )}
						options={pageState.filterOptions!.unitStatus}
						onChange={(d) => onSelectUnitStatus(d)}
						disabled={additionalDropdownDisabled()}
						hideClear={false}
						filter
						style={{ width: 200 }}
					></MultiSelect>						
				</div>
				<div className={classes.filterItem}>
					{"Vacant Days:"}
					<InputWithOperatorFilter 
						filter={{name: "Vacant Days", value: localState.currentFilters.vacantDays, operator: localState.currentFilters.vacantDaysOperator} as InputWithOperatorFilterSelection}
						operatorOptions={pageState.filterOptions?.vacantDaysOperator ?? []}
						onDropdownChange={(e) => {setLocalState({...localState, currentFilters: {...localState.currentFilters, vacantDaysOperator: e}})}}
						onValueChange={(e) => {setLocalState({...localState, currentFilters: {...localState.currentFilters, vacantDays: e}})}}
					 />
				</div>

				<Button
					label="Apply"
					className={applySearchHighlight()}
					onClick={() => applySearch()}
					disabled={applySearchDisabled()}
				></Button>
				<div className={classes.exportButton}>
					<Button
						label="Export"
						className={classes.applyButton}
						onClick={() => applyExport()}
						disabled={applySearchDisabled()}
					></Button>
				</div>
			</div>
			<div id="contentContainer" className={classes.contentContainer}>
				{pageState.isLoading ? <ProgressSpinner /> : <div className={classes.pageHeading}> {displayReport()}</div>}
			</div>
		</Page>
	);
};

export default StaleUnitPage;
